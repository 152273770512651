exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-ceo-js": () => import("./../../../src/pages/company/ceo.js" /* webpackChunkName: "component---src-pages-company-ceo-js" */),
  "component---src-pages-company-history-js": () => import("./../../../src/pages/company/history.js" /* webpackChunkName: "component---src-pages-company-history-js" */),
  "component---src-pages-company-introduction-js": () => import("./../../../src/pages/company/introduction.js" /* webpackChunkName: "component---src-pages-company-introduction-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-product-carrier-js": () => import("./../../../src/pages/product/carrier.js" /* webpackChunkName: "component---src-pages-product-carrier-js" */),
  "component---src-pages-product-cartridge-js": () => import("./../../../src/pages/product/cartridge.js" /* webpackChunkName: "component---src-pages-product-cartridge-js" */)
}

